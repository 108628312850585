<template>
  <div id="app">
    <ExpressionGenerator />
  </div>
</template>

<script>
import ExpressionGenerator from './components/ExpressionGenerator.vue';

export default {
  name: 'App',
  components: {
    ExpressionGenerator
  }
};
</script>

<style>
@import url('@/assets/styles.css');
</style>