<template>
  <div id="app">
    <!-- 上栏 -->
    <header class="header">
      <div class="logo">网站Logo</div>
      <nav class="nav">
        <button v-if="isLoggedIn" @click="showUserId">{{ userId }}</button>
        <button v-else @click="login">登录</button>
        <button @click="showFriendsList">好友列表</button>
        <button @click="toggleSettings">设置</button>
      </nav>
    </header>

    <!-- 下栏 -->
    <main class="main">
      <div class="left-column">
        <form @submit.prevent="handleSubmit">
          <label for="operation">运算类型:</label>
          <select v-model="selectedOperation" id="operation">
            <option value="加法">加法</option>
            <option value="减法">减法</option>
            <option value="乘法">乘法</option>
            <option value="除法">除法</option>
            <option value="混合">混合</option>
          </select>

          <label for="difficulty">难度:</label>
          <select v-model="selectedDifficulty" id="difficulty">
            <option value="初级">初级</option>
            <option value="中级">中级</option>
            <option value="高级">高级</option>
          </select>

          <label for="content">内容:</label>
          <select v-model="selectedContent" id="content">
            <option value="小学一年级">小学一年级</option>
            <option value="二年级">二年级</option>
            <option value="三年级">三年级</option>
            <option value="四年级">四年级</option>
            <option value="五年级">五年级</option>
            <option value="六年级">六年级</option>
          </select>

          <label for="decimalCalculation">是否带小数计算:</label>
          <input type="checkbox" v-model="isDecimalCalculation" id="decimalCalculation">

          <label for="numberOfProblems">生成算式的数量:</label>
          <input type="number" v-model.number="numberOfProblems" id="numberOfProblems" min="1" max="20">

          <button type="submit">确认</button>
        </form>
      </div>
      <div class="right-column">
        <div v-for="(problem, index) in problems" :key="index" class="problem" :class="{ 'correct': problem.status === 'correct', 'incorrect': problem.status === 'incorrect', 'unanswered': problem.status === 'unanswered' }">
          <p>{{ problem.expression }}</p>
          <input type="text" v-model="problem.userAnswer" placeholder="请输入答案">
        </div>
        <button @click="submitAnswers" :disabled="!canSubmit">提交</button>
      </div>
    </main>

    <!-- 设置弹出框 -->
    <div v-if="isSettingsOpen" class="settings-popup">
      <button @click="logout">退出登录</button>
      <button @click="toggleSettings">关闭</button>
    </div>
  </div>
</template>
  
<script>
import ExpressionGeneratorLogic from './ExpressionGeneratorLogic.js';

export default {
  ...ExpressionGeneratorLogic,
  data() {
    return {
      ...ExpressionGeneratorLogic.data(),
      score: 0
    };
  },
  computed: {
    canSubmit() {
      // 检查是否有未回答的问题
      return this.problems.every(problem => problem.userAnswer !== '');
    }
  },

};
</script>

<style scoped>
/* 如果需要局部样式，可以在这里定义 */
.problem {
  margin-bottom: 10px;
}

.correct {
  border: 2px solid green;
}

.incorrect {
  border: 2px solid red;
}

.unanswered {
  border: 2px solid red;
}
</style>
