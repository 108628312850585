export default {
    name: 'ExpressionGenerator',
    data() {
      return {
        isLoggedIn: false, // 登录状态
        userId: 'User123', // 用户ID
        isSettingsOpen: false, // 设置弹出框是否打开
        selectedOperation: '加法', // 选中的运算类型
        selectedDifficulty: '初级', // 选中的难度
        selectedContent: '小学一年级', // 选中的内容
        isDecimalCalculation: false, // 是否带小数计算
        numberOfProblems: 5, // 生成算式的数量
        problems: [] // 生成的算式列表
      };
    },
    methods: {
      login() {
        // 模拟登录
        this.isLoggedIn = true;
      },
      logout() {
        // 模拟登出
        this.isLoggedIn = false;
        this.isSettingsOpen = false;
      },
      showUserId() {
        alert(`当前用户ID: ${this.userId}`);
      },
      showFriendsList() {
        alert('好友列表');
      },
      toggleSettings() {
        this.isSettingsOpen = !this.isSettingsOpen;
      },
      handleSubmit() {
        this.generateProblems();
      },
      generateProblems() {
        //const operations = ['+', '-', '*', '/'];
        const difficulties = {
          初级: { min: 1, max: 10 },
          中级: { min: 10, max: 100 },
          高级: { min: 100, max: 1000 }
        };
  
        const difficultyRange = difficulties[this.selectedDifficulty];
        this.problems = [];
  
        for (let i = 0; i < this.numberOfProblems; i++) {
          let expression = '';
          let result = 0;
  
          if (this.selectedOperation === '混合') {
            // 生成混合运算表达式
            expression = this.generateMixedExpression(difficultyRange);
            try {
              result = eval(expression);
            } catch (e) {
              // 如果表达式无效，重新生成
              i--;
              continue;
            }
  
            // 确保结果是数字
            if (isNaN(result)) {
              i--;
              continue;
            }
          } else {
            // 单一运算类型的表达式
            const a = this.getRandomNumber(difficultyRange.min, difficultyRange.max);
            const b = this.getRandomNumber(difficultyRange.min, difficultyRange.max);
            const operation = this.selectedOperation;
  
            if (operation === '加法') {
              result = a + b;
              expression = `${a} + ${b}`;
            } else if (operation === '减法') {
              result = a - b;
              expression = `${a} - ${b}`;
            } else if (operation === '乘法') {
              result = a * b;
              expression = `${a} * ${b}`;
            } else if (operation === '除法') {
              if (b === 0) {
                i--;
                continue;
              }
              result = a / b;
              expression = `${a} / ${b}`;
            }
          }
  
          this.problems.push({
            expression,
            userAnswer: ''
          });
        }
      },
      generateMixedExpression(difficultyRange) {
        const operations = ['+', '-', '*', '/'];
        const numOperands = Math.floor(Math.random() * 4) + 2; // 2到5个操作数
        const useParentheses = Math.random() > 0.5; // 50%的概率使用括号
        const operands = [];
  
        for (let i = 0; i < numOperands; i++) {
          operands.push(this.getRandomNumber(difficultyRange.min, difficultyRange.max));
        }
  
        let expression = '';
  
        if (useParentheses && numOperands > 2) {
          const startParenthesis = Math.floor(Math.random() * (numOperands - 1));
          const endParenthesis = Math.floor(Math.random() * (numOperands - 1)) + 1;
  
          for (let i = 0; i < numOperands; i++) {
            if (i > 0) {
              const operation = operations[Math.floor(Math.random() * operations.length)];
              expression += ` ${operation} `;
            }
  
            if (i === startParenthesis) {
              expression += '(';
            }
  
            expression += operands[i];
  
            if (i === endParenthesis) {
              expression += ')';
            }
          }
        } else {
          for (let i = 0; i < numOperands; i++) {
            if (i > 0) {
              const operation = operations[Math.floor(Math.random() * operations.length)];
              expression += ` ${operation} `;
            }
            expression += operands[i];
          }
        }
  
        return expression;
      },
      getRandomNumber(min, max) {
        if (this.isDecimalCalculation) {
          return (Math.random() * (max - min) + min).toFixed(2);
        } else {
          return Math.floor(Math.random() * (max - min + 1)) + min;
        }
      },
      submitAnswers() {
        this.score = 0;
        this.problems.forEach((problem) => {
          try {
            const correctAnswer = eval(problem.expression);
            const userAnswer = parseFloat(problem.userAnswer);
  
            if (isNaN(userAnswer)) {
              problem.status = 'unanswered';
            } else if (Math.abs(correctAnswer - userAnswer) < 0.001) {
              problem.status = 'correct';
              this.score++;
            } else {
              problem.status = 'incorrect';
            }
          } catch (e) {
            problem.status = 'unanswered';
          }
        });
  
        // 显示分数
        alert(`你的得分是：${this.score} / ${this.problems.length}`);
      }
    }
  };